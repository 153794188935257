<template>
  <section class="container">
    <stockTopMenu curPosition="时间段业绩" windowId="timeQuantum" module="timeQuantum"></stockTopMenu>
    <div class="date-range row align-center">
      <span>选择日期</span>
      <el-date-picker
          placeholder="请选择日期"
          v-model="dateTime"
          type="date"
          size="small"
          value-format="yyyyMMdd"
          :clearable="false">
      </el-date-picker>
      <el-button class="sub-btn" type="primary" size="small" :loading="searchLoading" @click="page = 1;fetch();">查 询</el-button>
    </div>
    <main class="main-table"
      v-loading="searchLoading"
      element-loading-text="请稍后，全力加载中..."
      element-loading-spinner="el-icon-loading">
      <el-table
        border
        height="800"
        style="overflow: unset;"
        :data="tableData"
        :show-summary="true"
        :header-cell-style="{padding: '6px', background: 'rgb(180,198,231)', color: '#000', fontWeight: 'bold', fontSize: '14px'}"
        :cell-style="{padding: '5px'}"
        :span-method="objectSpanMethod"
        :summary-method="summaryHandel">
        <el-table-column
            column-key="column"
            prop="timeSlot"
            align="center"
            label="时间段">
        </el-table-column>
        <el-table-column
            prop="userName"
            label="姓名">
        </el-table-column>
<!--        <el-table-column-->
<!--            prop="post"-->
<!--            label="岗位">-->
<!--        </el-table-column>-->
        <el-table-column
            prop="dayAmount"
            label="业绩">
        </el-table-column>
        <el-table-column
            prop="invoicesNum"
            label="单数">
        </el-table-column>
        <el-table-column
            prop="skuNum"
            label="件数">
        </el-table-column>
        <el-table-column
            prop="customerUnitPrice"
            label="客单价">
        </el-table-column>
        <el-table-column
            prop="unitPrice"
            label="件单价">
        </el-table-column>
        <el-table-column
            prop="joinRate"
            label="连带率">
        </el-table-column>
      </el-table>
      <el-pagination
          style="margin-top: 55px;width: 100%;"
          background
          layout="total, prev, pager, next, sizes"
          :page-sizes="[1, 2, 3, 4, 5]"
          :current-page.sync="page"
          :page-size="pageSize"
          :total="totalItem"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </main>
  </section>
</template>

<script>
import moment from "moment";
import stockTopMenu from '@/components/stockTopMenu.vue'
import {queryTimeQuantum} from "@/libs/http/api";
export default {
  name: 'timeQuantum',
  components: {
    stockTopMenu,
  },
  data(){
    return{
      dateTime: moment().format('yyyyMMDD'),
      searchLoading: false,
      tableData: [],
      spanArr: [],
      summaryArr: [],
      totalItem: 0,
      page: 1,
      pageSize: 4
    }
  },
  created() {
    this.fetch();
  },
  methods: {
    handleSizeChange(e){
      this.pageSize = e;
      this.page = 1;
      this.fetch();
    },
    handleCurrentChange(e){
      this.page = e;
      this.fetch();
    },
    fetch(){
      this.searchLoading = true;
      queryTimeQuantum({
        queryDate: this.dateTime,
        page: this.page + '',
        pageSize: this.pageSize + ''
      }).then(data=>{
        this.searchLoading = false;
        if(data && data.dayAmountTimeSlots){
          this.totalItem = Number(data.totalItem) || 0;
          this.tableData = data.dayAmountTimeSlots;
          this.summaryArr = ['本日汇总' , '', data.dayAmountSum, data.invoicesNumSum, data.skuNumSum, data.customerUnitPriceSum, data.unitPriceSum, data.joinRateSum]
          this.rowspan(this.tableData);
        }
      }).catch(error=>{
        this.searchLoading = false;
        this.$message({type: 'error', message: error, duration: 2000, showClose: true})
      })
    },
    rowspan(data) {
      this.spanArr = [];
      let pos;
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          pos = 0
        } else if ( data[i].timeSlot && data[i].timeSlot === data[i - 1].timeSlot) {
          this.spanArr[pos] += 1
          this.spanArr.push(0)
        } else {
          this.spanArr.push(1)
          pos = i
        }
      }
    },
    objectSpanMethod({ row, column, rowIndex, columnIndex }) {
      if (columnIndex === 0) {
        const _row = this.spanArr[rowIndex]
        const _col = _row > 0 ? 1 : 0
        return {
          rowspan: _row,
          colspan: _col
        }
      }
    },
    summaryHandel({ columns, data }) {
      const sums = [];
      columns.forEach((column, index) => {
        const values = data.map(item => Number(item[column.property]));
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] += ' 元';
        } else {
          sums[index] = 'N/A';
        }
      });
      return this.summaryArr;
    }
  }
}
</script>

<style lang="scss" scoped>
.date-range{
  padding: 10px;
  span{
    margin-right: 10px;
  }
  .sub-btn{
    margin-left: 10px;
  }
}
.main-table{
  padding: 10px;
  margin: 0 10px;
  background: #fff;
  height: 88vh;
  overflow: auto;
}

::v-deep .el-table__footer-wrapper {
  .el-table__footer {
    td.el-table__cell{
      background: rgb(146,208,80);
      color: #000;
      font-weight: bold;
    }
    td:first-child{
      font-size: 14px;
    }
  }
}
</style>

<style>
.main-table .el-table_1_column_1{
  background: rgb(253,250,174);
  color: #000;
  font-weight: bold;
  font-size: 20px;
}
.main-table .el-table_1_column_3{
  color: red;
  font-weight: bold;
}
</style>